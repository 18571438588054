import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Home.css';

const Home = ({ city }) => {
    const [notifications, setNotifications] = useState([]);
    const [news, setNews] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [selectedNews, setSelectedNews] = useState(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/contacts`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    params: { city }
                });
                console.log('Notifications:', response.data); // Log the data
                setNotifications(response.data);
            } catch (err) {
                console.error('Failed to fetch notifications', err);
            }
        };

        const fetchNews = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/news`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    params: { city }
                });
                console.log('News:', response.data); // Log the data
                setNews(response.data);
            } catch (err) {
                console.error('Failed to fetch news', err);
            }
        };

        const fetchSurveys = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/surveys`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    params: { city }
                });
                console.log('Surveys:', response.data); // Log the data
                setSurveys(response.data);
            } catch (err) {
                console.error('Failed to fetch surveys', err);
            }
        };

        fetchNotifications();
        fetchNews();
        fetchSurveys();
    }, [city]);

    const handleNewsClick = (article) => {
        setSelectedNews(article);
    };

    const closePopup = () => {
        setSelectedNews(null);
    };

    return (
        <div className="home-container">
            <h1>Welcome to {city}!</h1>
            <div className="top-section">
                <div className="column latest-messages">
                    <h2>Latest Messages</h2>
                    {/* Placeholder content for latest messages */}
                    <div className="message">Message 1: Lorem ipsum dolor sit amet.</div>
                    <div className="message">Message 2: Consectetur adipiscing elit.</div>
                    <div className="message">Message 3: Integer molestie lorem at massa.</div>
                </div>
                <div className="column notifications">
                    <h2>Latest City Notifications/Surveys</h2>
                    {notifications.map(notification => (
                        <div key={notification.id} className="notification">
                            {notification.name}: {notification.type}
                        </div>
                    ))}
                    <h3>Surveys</h3>
                    {surveys.map(survey => (
                        <div key={survey.id} className="survey">
                            {survey.title}: {survey.description}
                        </div>
                    ))}
                </div>
            </div>
            <div className="news-section">
                <h2>Latest News of the City</h2>
                <div className="news-grid">
                    {news.map(article => (
                        <div key={article.id} className="news-article" onClick={() => handleNewsClick(article)}>
                            <img src={article.image_url} alt={article.title} />
                            <p>{article.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            {selectedNews && (
                <div className="news-popup" onClick={closePopup}>
                    <div className="news-popup-content" onClick={(e) => e.stopPropagation()}>
                        <h2>{selectedNews.title}</h2>
                        <img src={selectedNews.image_url} alt={selectedNews.title} />
                        <p>{selectedNews.content}</p>
                        <button onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            <div className="procedures-advancement">
                <h2>My Administrative Procedures Advancement</h2>
                {/* Placeholder for administrative procedures advancement */}
                <div className="procedure">Procedure 1: In progress</div>
                <div className="procedure">Procedure 2: Completed</div>
            </div>
        </div>
    );
};

export default Home;
