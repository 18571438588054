import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Administrative.css';

const Administrative = ({ city }) => {
    const [procedures, setProcedures] = useState([]);

    useEffect(() => {

        const fetchProcedures = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/admin/procedures', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    params: { city }
                });
                setProcedures(response.data);
            } catch (err) {
                console.error('Failed to fetch procedures', err);
            }
        };

        fetchProcedures();
    }, [city]);

    return (
        <div className="administrative-container">
            <h1>Administrative Procedures in {city}</h1>
            <div className="top-section">
                <div className="column latest-messages">
                    <h2>My Procedures</h2>
                    {/* Placeholder content for latest messages */}
                    <div className="message">>Permis de conduire</div>
                </div>
                <div className="column notifications">
                    <h2>Démarrer une procédure</h2>
                    <h3>>Renouvellement de passeport</h3>
                    <h3>>Certificat de Mariage</h3>
                    <h3>>Fiche URSAFF 2024</h3>
                </div>
            </div>
            <div className="procedures-advancement">
                <h2>Mes avancements de de procédures en cours</h2>
                <h3>>25/05/2024: Permis de conduire: dossier en attente</h3>
                {procedures.map(procedure => (
                    <div key={procedure.id} className="procedure">
                        {procedure.name}: {procedure.status}
                    </div>
                ))}
            </div>
            <div className="procedures-advancement">
                <h2>Lancer une discussion avec le Chatbot</h2>
                <h3>[_______commencer_______]</h3>
                {procedures.map(procedure => (
                    <div key={procedure.id} className="procedure">
                        {procedure.name}: {procedure.status}
                    </div>

                ))}
            </div>
        </div>
    );
};

export default Administrative;
