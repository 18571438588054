import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Survey.css';

const Survey = ({ city }) => {
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                const response = await axios.get('${process.env.REACT_APP_API_URL}/admin/surveys', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    params: { city }
                });
                setSurveys(response.data);
            } catch (err) {
                console.error('Failed to fetch surveys', err);
            }
        };

        fetchSurveys();
    }, [city]);

    const handleOptionClick = (surveyId, option) => {
        // This is where you can handle the option click, e.g., sending the selected option to the backend
        console.log(`Survey ID: ${surveyId}, Option: ${option}`);
    };

    return (
        <div className="survey-container">
            <h1>Surveys</h1>
            {surveys.map(survey => (
                <div key={survey.id} className="survey-card">
                    <h2>{survey.title}</h2>
                    <p>{survey.description}</p>
                    <div className="options">
                        {survey.options.map((option, index) => (
                            <button key={index} className="option-button" onClick={() => handleOptionClick(survey.id, option)}>
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Survey;
