import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddContent.css';

const AddContent = ({ city }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [type, setType] = useState('news'); // default type
    const [options, setOptions] = useState(['']); // for survey options
    const [contactDetails, setContactDetails] = useState({ phone: '', email: '', address: '' });
    const [news, setNews] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        fetchContent();
    }, [city]);

    const fetchContent = async () => {
        try {
            const newsResponse = await axios.get('http://localhost:5000/api/admin/news', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                params: { city }
            });
            setNews(newsResponse.data);

            const surveysResponse = await axios.get('http://localhost:5000/api/admin/surveys', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                params: { city }
            });
            setSurveys(surveysResponse.data);

            const contactsResponse = await axios.get('http://localhost:5000/api/admin/contacts', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                params: { city }
            });
            setContacts(contactsResponse.data);
        } catch (err) {
            console.error('Failed to fetch content', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let url = '';
            let data = {};
            if (type === 'news') {
                url = 'http://localhost:5000/api/admin/news';
                data = { title, content, imageUrl, city };
            } else if (type === 'survey') {
                url = 'http://localhost:5000/api/admin/surveys';
                data = { title, description: content, options, city };
            } else if (type === 'contact') {
                url = 'http://localhost:5000/api/admin/contacts';
                data = { name: title, type: content, phone: contactDetails.phone, email: contactDetails.email, address: contactDetails.address, city };
            }
            await axios.post(url, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            fetchContent();
            // Clear form fields after successful submission
            setTitle('');
            setContent('');
            setImageUrl('');
            setOptions(['']);
            setContactDetails({ phone: '', email: '', address: '' });
        } catch (err) {
            console.error('Failed to add content', err);
        }
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const addOption = () => {
        setOptions([...options, '']);
    };

    const removeOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    const handleContactDetailChange = (e) => {
        const { name, value } = e.target;
        setContactDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    };

    const handleDelete = async (type, id) => {
        try {
            let url = '';
            if (type === 'news') {
                url = `${process.env.REACT_APP_API_URL}/admin/news/${id}`;
            } else if (type === 'survey') {
                url = `${process.env.REACT_APP_API_URL}/admin/surveys/${id}`;
            } else if (type === 'contact') {
                url = `${process.env.REACT_APP_API_URL}/admin/contacts/${id}`;
            }
            await axios.delete(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            fetchContent();
        } catch (err) {
            console.error(`Failed to delete ${type}`, err);
        }
    };

    return (
        <div className="add-content-container">
            <h2>Add Content</h2>
            <form onSubmit={handleSubmit}>
                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="news">News</option>
                    <option value="survey">Survey</option>
                    <option value="contact">Contact</option>
                </select>
                <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <textarea
                    placeholder="Content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    required
                />
                {type === 'news' && (
                    <input
                        type="text"
                        placeholder="Image URL"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                    />
                )}
                {type === 'survey' && (
                    <div className="survey-options">
                        <h3>Options</h3>
                        {options.map((option, index) => (
                            <div key={index} className="option">
                                <input
                                    type="text"
                                    value={option}
                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                    placeholder={`Option ${index + 1}`}
                                    required
                                />
                                <button type="button" onClick={() => removeOption(index)}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={addOption}>
                            Add Option
                        </button>
                    </div>
                )}
                {type === 'contact' && (
                    <div className="contact-details">
                        <input
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            value={contactDetails.phone}
                            onChange={handleContactDetailChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={contactDetails.email}
                            onChange={handleContactDetailChange}
                            required
                        />
                        <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={contactDetails.address}
                            onChange={handleContactDetailChange}
                            required
                        />
                    </div>
                )}
                <button type="submit">Add {type}</button>
            </form>
            <div className="existing-content">
                <h2>Existing News</h2>
                <ul>
                    {news.map(article => (
                        <li key={article.id}>
                            {article.title}
                            <button onClick={() => handleDelete('news', article.id)}>Delete</button>
                        </li>
                    ))}
                </ul>
                <h2>Existing Surveys</h2>
                <ul>
                    {surveys.map(survey => (
                        <li key={survey.id}>
                            {survey.title}
                            <button onClick={() => handleDelete('survey', survey.id)}>Delete</button>
                        </li>
                    ))}
                </ul>
                <h2>Existing Contacts</h2>
                <ul>
                    {contacts.map(contact => (
                        <li key={contact.id}>
                            {contact.name} - {contact.type} - {contact.phone} - {contact.email} - {contact.address}
                            <button onClick={() => handleDelete('contact', contact.id)}>Delete</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AddContent;
