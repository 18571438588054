import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Community from './components/Community/Community';
import Survey from './components/Survey/Survey';
import ContactSearch from './components/ContactSearch/ContactSearch';
import Administrative from './components/Administrative/Administrative';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import AddContent from './components/Mayor/AddContent';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [city, setCity] = useState(localStorage.getItem('city'));
    const [role, setRole] = useState(localStorage.getItem('role'));

    const handleLogin = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            setCity(decodedToken.city);
            setRole(decodedToken.role);
            setIsAuthenticated(true);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setCity(null);
        setRole(null);
        setIsAuthenticated(false);
    };

    useEffect(() => {
        handleLogin();
    }, []);

    return (
        <Router>
            <div className="App">
                <header className={role === 'mayor' ? 'App-header-mayor' : 'App-header'}>
                    <img src="/logo.png" className="App-logo" alt="logo" />
                    {isAuthenticated && (
                        <input type="text" placeholder="Recherche..." className="search-input" />
                    )}
                    {isAuthenticated && (
                        <button onClick={handleLogout} className="logout-button">Logout</button>
                    )}
                </header>
                <nav className="App-nav">
                    {isAuthenticated ? (
                        <>
                            <Link to="/">Home</Link>
                            {role === 'mayor' ? (
                                <>
                                    <div className="nav-separator"></div>
                                    <Link to="/add-content">add content</Link>
                                </>
                            ) : (
                                <>
                                    <div className="nav-separator"></div>
                                    <Link to="/community">Community</Link>
                                    <div className="nav-separator"></div>
                                    <Link to="/survey">Survey</Link>
                                    <div className="nav-separator"></div>
                                    <Link to="/contact">Contact</Link>
                                    <div className="nav-separator"></div>
                                    <Link to="/administrative">Administrative</Link>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Link to="/login">Login</Link>
                            <div className="nav-separator"></div>
                            <Link to="/register">Register</Link>
                        </>
                    )}
                </nav>
                <main>
                    <Routes>
                        <Route path="/" element={isAuthenticated ? <Home city={city} /> : <Navigate to="/login" />} />
                        <Route path="/community" element={isAuthenticated ? <Community city={city} role={role} /> : <Navigate to="/login" />} />
                        <Route path="/survey" element={isAuthenticated ? <Survey city={city} /> : <Navigate to="/login" />} />
                        <Route path="/contact" element={isAuthenticated ? <ContactSearch city={city} /> : <Navigate to="/login" />} />
                        <Route path="/administrative" element={isAuthenticated ? <Administrative city={city} /> : <Navigate to="/login" />} />
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                        <Route path="/register" element={<Register onRegister={handleLogin} />} />
                        <Route path="/add-content" element={isAuthenticated && role === 'mayor' ? <AddContent city={city} /> : <Navigate to="/" />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
