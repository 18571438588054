import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './Community.css';

// Import the custom icon
import customIconUrl from './Google_Maps_icon_(2020).svg.png';

// Define the custom icon
const customIcon = new L.Icon({
    iconUrl: customIconUrl,
    iconSize: [25, 41], // size of the icon
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png', // optional shadow
    shadowSize: [41, 41], // size of the shadow
    shadowAnchor: [12, 41] // point of the shadow which will correspond to shadow's location
});

function Community({ city, role }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [points, setPoints] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [formType, setFormType] = useState('');
    const [description, setDescription] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');

    useEffect(() => {
        const fetchPoints = async () => {
            try {
                const response = await axios.get('${process.env.REACT_APP_API_URL}/points', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    params: { city }
                });
                setPoints(response.data);
            } catch (err) {
                console.error('Failed to fetch points', err);
            }
        };

        fetchPoints();
    }, [city]);

    const handleAddEventClick = (type) => {
        setFormType(type);
        setShowForm(true);
    };

    const handleAddPoint = async () => {
        if (lat && lng && description) {
            const payload = {
                lat,
                lng,
                description,
                city
            };

            console.log("Payload:", payload);  // Log the payload to verify its content
            console.log("Token:", localStorage.getItem('token'));  // Log the token to verify

            try {
                const response = await axios.post('${process.env.REACT_APP_API_URL}/points', payload, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setPoints([...points, response.data]);
                setShowForm(false);
                setDescription('');
                setLat('');
                setLng('');
            } catch (err) {
                console.error('Failed to add point', err);
            }
        }
    };

    const handleDeletePoint = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/points/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setPoints(points.filter(point => point.id !== id));
        } catch (err) {
            console.error('Failed to delete point', err);
        }
    };

    const AddMarker = () => {
        useMapEvents({
            click(e) {
                setLat(e.latlng.lat);
                setLng(e.latlng.lng);
            }
        });
        return null;
    };

    return (
        <div className="Community">
            <h1>Community Hub</h1>
            <p>Engage with your local community. Share, discuss, and connect!</p>
            <input
                type="text"
                className="search-bar"
                placeholder="Search for events..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="button-container">
                <button className="btn red" onClick={() => handleAddEventClick('emergency')}>Add an Emergency</button>
                <button className="btn green" onClick={() => handleAddEventClick('long')}>Add a Long Event</button>
                <button className="btn blue" onClick={() => handleAddEventClick('current')}>Add a Current Event</button>
                <button className="btn purple" onClick={() => handleAddEventClick('private')}>Add a Private Event</button>
            </div>
            {showForm && (
                <div className="form-popup">
                    <h2>Add {formType} Event</h2>
                    <input
                        type="text"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Latitude"
                        value={lat}
                        onChange={(e) => setLat(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Longitude"
                        value={lng}
                        onChange={(e) => setLng(e.target.value)}
                        required
                    />
                    <div className="tiny-map-container">
                        <MapContainer center={[48.866669, 2.33333]} zoom={13} className="tiny-map">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <AddMarker />
                            {lat && lng && (
                                <Marker position={[lat, lng]} icon={customIcon}>
                                    <Popup>Selected Location</Popup>
                                </Marker>
                            )}
                        </MapContainer>
                    </div>
                    <button onClick={handleAddPoint}>Add Event</button>
                    <button onClick={() => setShowForm(false)}>Cancel</button>
                </div>
            )}
            <div className="map-container">
                <h2>Community Map</h2>
                <MapContainer center={[48.866669, 2.33333]} zoom={13} className="map">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {points.map(point => (
                        <Marker key={point.id} position={[point.lat, point.lng]} icon={customIcon}>
                            <Popup>
                                {point.description}
                                {role === 'mayor' && (
                                    <button onClick={() => handleDeletePoint(point.id)}>Delete</button>
                                )}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
        </div>
    );
}

export default Community;
