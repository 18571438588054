import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactSearch.css';

const ContactSearch = ({ city }) => {
    const [contacts, setContacts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get('${process.env.REACT_APP_API_URL}/admin/contacts', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    params: { city }
                });
                setContacts(response.data);
            } catch (err) {
                console.error('Failed to fetch contacts', err);
            }
        };

        fetchContacts();
    }, [city]);

    const filteredContacts = contacts.filter(contact =>
        contact.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="contact-search-container">
            <h1>Contact Search</h1>
            <input
                type="text"
                className="search-bar"
                placeholder="Search contacts..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="contacts-list">
                {filteredContacts.map(contact => (
                    <div key={contact.id} className="contact-item">
                        <h2>{contact.name}</h2>
                        <p>Type: {contact.type}</p>
                        <p>Phone: {contact.phone}</p>
                        <p>Email: {contact.email}</p>
                        <p>Address: {contact.address}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContactSearch;
